.jinga_zh {
  letter-spacing: 0.5px;

  strong{
    font-weight: bold;
  }

  //解决方案弹窗菜单
  @media screen and (min-width: 992px) {
    .navbar-default #menu-top-menu>li>.sub-menu {
      width: 600px;
    }
  }
  .menu-title{
    font-weight: bold;
  }
  .nav-min .navbar-nav li a{
    font-family: '微软雅黑';
  }
  @media screen and (min-width: 992px){
    .navbar-default #menu-top-menu > li .sub-menu li .sub-menu li a{
    color: #fffefe !important;
    font-family: '微软雅黑';
    font-weight: lighter;
  }
  }


  // 首页 home
  .homeTitle{
    font-size:40px;
    font-family:'华文细黑';
    line-height:60p;
  }
  .nav-min .navbar-nav li {
    padding: 7px 50px 0 0;
  }

  .incorp h1 {
    margin-top: 50px;
  }

  .incorp p {
    width: 76%;
    line-height: 30px;
  }

  .vedio {
    margin: 15px 0 0 25px;
  }

  .entit h2 {
    font-size: 30px;
  }

  .filing h3 {
    font-size: 18px;
  }

  .filing p {
    font-size: 14px;
  }

  .audit {
    min-height: 300px;
  }

  .audit li {
    font-size: 12px;
    font-family: 微软雅黑;
    color: #918f8f;
  }

  .avai-btn button.btn-pink {
    font-size: 16px;
  }

  .check-form input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    color: #918f8f;
  }

  .check-form input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 适配火狐 */
    color: #918f8f;
  }

  .check-form input::-moz-placeholder {
    /* Mozilla Firefox 19+ 适配火狐 */
    color: #918f8f;
  }

  .check-form input:-ms-input-placeholder {
    /* Internet Explorer 10+  适配ie*/
    color: #918f8f;
  }

  .carousel .item {
    min-height: 400px;
  }

  .filing h3 {
    margin: 0 0 20px;
  }

  .tabs-row.vie .filing p {
    font-size: 14px;
  }

  .carousel .testimonial {
    min-height: 190px;
    text-align: left;

  }

  .directly {
    padding: 170px 0 0 0;
  }

  .all-ct {
    margin: 80px 0 0 0;
  }

  .app-colam h4 {
    font-size: 30px;

  }

  .app-colam p {
    margin-bottom: 45px;
    padding-top: 0 !important;
  }

  .app-colam h1 {
    font-size: 48px;
  }

  // HeadBanner
  h1.large-heading,
  .large-heading {
    margin: 40px 0 17px 0 !important;
    letter-spacing: 0;
  }

  .gray-bg {
    padding: 4.5px 0;
    font-family: '微软雅黑';
  }

  // 解决方案 oursolution
  .outer {
    padding-top: 0;
  }

  .our_solutions_title {
    font-family: '华文细黑';
    font-weight: 600;
    margin-bottom: 23px !important;
  }

  .custom-list {
    font-size: 16px;
    line-height: 26px;
  }

  // 联系我们  contact
  .contact{
    padding-top: 60px;
  }

  // 关于我们  about
  .about {
    padding-top: 80px;

    .about_title {
      margin-bottom: 40px;
    }

    .about_content {
      margin-bottom: 20px;
    }

    .about_itemTitle {
      font-size: 30px;
      margin-bottom: 30px;
    }

    .pb-5,
    .py-5,
    .pt-4,
    .py-4 {
      padding-top: 0 !important;
    }
    .graybox .card-text{
      font-family: '微软雅黑';
    }

    .noMarBottom {
      margin-bottom: 0 !important;
    }
  }

  // knowledge 知识中心
  .knowledge {
    padding-top: 80px;
  }

  // order from
  .order {
    padding-top: 40px;

    .left-sidebar {
      padding-top: 30px;
    }

    .cat-title {
      margin-top: 0;
      font-weight: bold;
      font-family: '华文细黑';
    }

    .catlist li a{
      font-family: '宋体';
    }

    .heading{
      font-size: 22px;
      font-family: '华文细黑';
    }
    .heading_content{
      color: #918f8f;
    }

    .order-sidebar {

      .order-list li.orderdesc .cart-info,
      .order-box h4 {
        font-weight: 600;
      }
    }

    .order-box .order-list li>span:first-child strong {
      font-weight: bold;
      font-family: '微软雅黑';
    }

    .required-items li.title-top,
    .heading_2,
    li.heading_2 {
      font-size: 18px;
      font-weight: bold;
      font-family: '华文细黑';
    }

    .small-title, .heading_2 span:first-child{
      text-decoration: none;
      margin-bottom: 0;
    }

    .sub-underline-title{
      margin-bottom: 20px;
    }
    .item-title,.item-title1, .price {
      font-weight: bold;
      font-family: '微软雅黑';
    }
    .item-title{
      text-decoration: none !important;
      margin-bottom: 0;
    }
    .required-items li.mid-class-alpha .item-title{
      margin-bottom: 0!important;
    }
    .item-title1{
      display: inline;
    }
    .item-title1-tips{
      font-weight: normal;
      color: #5c5b5b;
      letter-spacing: 0;
    }

    .item-title-suffix{
      font-family: '微软雅黑';
      font-weight: normal;
      color: #5c5b5b;
    }
    .middleContent p{
      font-family: '微软雅黑';
    }

    .required-items li a{
      text-decoration: underline;
    }
    .chinese_title_prefix{
      font-weight: bold;
    }

    .order-box .order-list li > span:first-child, li.orderdesc .cart-info> span:first-child{
      font-family: '微软雅黑';
      font-weight: bold;
    }

    .coupon-wrap input::-webkit-input-placeholder {
      /* WebKit browsers 适配谷歌 */
      color: #888888;
      font-size: 12px;
      font-weight: '微软雅黑';
    }
  
    .coupon-wrap input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 适配火狐 */
      color: #888888;
      font-size: 12px;
      font-weight: '微软雅黑';
    }
  
    .coupon-wrap input::-moz-placeholder {
      /* Mozilla Firefox 19+ 适配火狐 */
      color: #888888;
      font-size: 12px;
      font-weight: '微软雅黑';
    }
  
    .coupon-wrap input:-ms-input-placeholder {
      /* Internet Explorer 10+  适配ie*/
      color: #888888;
      font-size: 12px;
      font-weight: '微软雅黑';
    }

    .coupon-wrap input[type="submit"]{
      font-family: '微软雅黑';
    }
    .checkout{
      font-family: '微软雅黑';
      font-size: 18px;
    }
    .terms{
      font-family: '宋体';
      color: #5c5b5b;
    }
    .child_product_top{
      margin-top: 20px !important;
      padding-bottom: 0;
    }

    .itemTitle-productId741,.itemTitle-productId701,.itemTitle-productId706{
      font-family: 'STXihei';
      font-size: 22px;
    }

    // 小标题
    .itemTitle-productId764,.itemTitle-productId147,.itemTitle-productId311,.itemTitle-productId318,.itemTitle-productId583,
    .itemTitle-productId623,.itemTitle-productId658,.itemTitle-productId446,.itemTitle-productId756,.itemTitle-productId759,
    .itemTitle-productId538{
      font-family: '华文细黑';
      font-size: 18px;
    }

    .required-items li{
      margin-bottom: 20px;
    }
    .required-items li ul li{
      margin-bottom: 0;
      font-size: 12px;
    }
    .product-769{
      margin-top: 20px;
    } 

    // 取消margin-bottom的模块
    .product-642 .item-title{
      margin-bottom: 0;
    }

    .dark-item-title p{
      color: #918f8f;
    }

    .no-top{
      margin-top: 0 !important;
    }
    .title-link{
      color: #000;
      a{
        font-weight: normal;
      }
    }
    .required-items li.sub-underline-title .item-title{
      margin-bottom: 0;
    }

    .required-items li.sub-medium-title{
      margin-bottom: 0 !important;
      .item-title1{
        font-family: '华文细黑';
      }
    }
    .sub-underline-title{
      .item-title1{
        font-family: '华文细黑';
        font-size: 18px;
      }
    }

    // 某些order from中的sub-underline-title标题要更宽
    .order-form-24 .required-items .sub-underline-title{
      margin-top: 40px;
    }

    // 取消一些order from中p标签下margin
    .order-form-45,.order-form-43,.order-form-44{
      .heading_content p {
        margin-bottom: 0;
      }
    }
  }

  // terms 条款和条件
  .terms-list {
    padding-top: 40px;
  }



  // footer 页脚
  .footer-titel {
    margin-bottom: 30px;
  }

  .footer-titel h2 {
    font-size: 22px;
    color: #4b7470;
    font-weight: bold;
  }

  .foot-about p,
  .foot-nav li a {
    font-size: 14px;
    font-family: 微软雅黑;
  }
 
  // common-business 海外架构
  .common-business{
    font-family: 微软雅黑;
    .tostring{
      font-weight: bold;
    }
    .left12{
      margin-left: 12px;
    }
    .border-line{
      p{
        margin-bottom: 0;
      }
    }
  }

  // 手机端
  @media (max-width:767px) {
    // 首页
    .homeBanner{
      .incorp h1{margin-top:0;}
      .incorp p{width: 84%;margin-bottom: 40px;}
    }
    .homeTitle{font-size: 27px;}
    .vedio{margin: 15px auto 0;}
    .incorpor{
      padding: 100px 0;
      .app-colam {margin-top: 10px}
      .app-colam h4 {font-size: 21px;color: #464646;}
      .app-colam h1 {font-size: 23px;}
      .app-colam p {margin-bottom: 10px;}
      .entit h2 {font-size: 22px;width: 84%;}
      .filing h3{margin: 10px 0 20px;}
      .all-ct {margin: 40px 0 0 0;}
      .jiga-app{padding: 50px 0 0 0;}
      .all-ct img {
        width: 50%;
        margin-left: 10px;
    }
    }
    .tabs-row.vie{margin: 100px 0 0 0;}
    .clients {margin: 100px 0 0 0;}
    .directly {padding: 100px 0 0 0;}
    .carousel .testimonial { text-align: center;}
    .row.footer-bottom{text-align: left;}
    .foot-social{-webkit-box-pack: left !important;justify-content: left !important;}
    


    // 关于我们
    .about{
      .about_title{font-size: 25px;}
      .small-heading{font-size: 25px;}
      .about_itemTitle{font-size: 22px;}
      .text18 {font-size: 18px;}
      // .pt-5{padding-top: 2rem!important;}
    }

    // order from
    .order{
      .title-top span:last-child, .heading_2 span:last-child {width: 33%;}
      .heading_2 span:first-child{width: 100%;}
      .price{padding-right: 10px;}
      .title-link{color: #918f8f;}
      .required-items li.title-top span:first-child{width: 67%;}
      .title-top{position: relative;}
      .required-items li.title-top span:last-child{position: absolute;right: -10px;}
    }

    // 全球架构
    .common-business{
      .large-heading{font-size: 30px;}
    }
  }
}
