.form-error {
	background-color: #BD0000;
	color: #fff;
	padding: 10px;
	font-size: 13px;
	margin-bottom: 8px;
}

.normal-message {
	min-height: 27px;
	display: inline-block;
}

.normal-message.success {
	color: #74c166;
}

.normal-message.error {
	color: #d03d3d;
}

.red-color {
	color: #A0545D;
}

.dgrey-color {
	color: #686667;
}

.text-uppercase {
	text-transform: uppercase;
}

.message {
	font-size: 15px;
}