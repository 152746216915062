.outer.bg-white.gray h2, .outer.bg-white.gray h3, .outer.bg-white.gray p, .outer.bg-white.gray li, .outer.bg-white.gray b, .outer.bg-white.gray td {
	/*color: #000 !important;*/
}
.privacy-policy tr td {
	padding-bottom: 0px;
	padding-top: 6px;
}

.privacy-policy {
	font-size: 14px;
}