

.swiper-container {
  width: 100%;
  height: 100%;
}
.news-box .swiper-slide{
  position: relative;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* border: 1px solid #000; */
  /* display: inline-block; */
  /* width: 33%; */
  /* margin: 20px; */
}

.news-box .swiper-pagination-bullet{
  width: 5px;
  height: 5px;
}

.news-box .swiper-pagination-bullet-active{
  background: #49736e;
}

.news-box .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 0;
}

.news-box .newsItem{
  position: absolute;
  top: 15px;
  width: 285px;
  height: 290px;
  background: #fff;
  /* border: 1px solid #000; */
  box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
}


.news-box .newsImage{
  height: 170px;
  /* border: 1px solid #000; */
}
.news-box .newsImage .newsimg{
  width: 100%;
  height: 100%;
}

.news-box .newsInfo{
  height: 120px;
  padding: 25px 15px;
  color: #444;
  /* border: 1px solid #000; */
}

.news-box .newsInfo .info-box{
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
